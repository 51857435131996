// Configuration for AWS Secrets Manager
const secretsConfig = {
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    }
};

// Get environment variables from runtime config
const getEnvVar = (key) => {
    return window._env_?.[key] || process.env[key];
};

const getApiUrl = () => {
    const env = process.env.NODE_ENV || 'development';
    return env === 'production'
        ? (getEnvVar('REACT_APP_API_URL') || 'https://www.tongy.io')  // Remove /api suffix
        : (getEnvVar('REACT_APP_API_URL') || 'http://localhost:5001');  // Development API URL
};

export const getConfig = async () => {
    try {
        // In development, use environment variables
        if (process.env.NODE_ENV === 'development') {
            return {
                cognito: {
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
                    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
                    region: process.env.REACT_APP_COGNITO_REGION
                }
            };
        }

        // In production, fetch from Secrets Manager
        const apiUrl = getApiUrl();
        console.log('Fetching configuration from:', apiUrl);
        const response = await fetch(`${apiUrl}/api/v1/config`);  // Add /api prefix here
        if (!response.ok) {
            throw new Error('Failed to fetch configuration');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching configuration:', error);
        throw error;
    }
};

export const getCognitoConfig = async () => {
    const config = await getConfig();
    return config.cognito;
};

export default {
    getConfig,
    getCognitoConfig
}; 