import React, { createContext, useContext, useState, useEffect } from 'react';
import authService from '../services/authService';

// Debug environment variables
console.log('All Environment Variables:', process.env);

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = async () => {
        try {
            const currentUser = await authService.getCurrentUser();
            if (!currentUser) {
                console.log('[AuthContext] No authenticated user');
                setUser(null);
                setError(null);
            } else {
                console.log('[AuthContext] User authenticated:', currentUser.username);
                setUser(currentUser);
                setError(null);
            }
        } catch (err) {
            console.error('[AuthContext] Error checking user:', err);
            setUser(null);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const login = async (email, password) => {
        try {
            const result = await authService.login(email, password);
            setUser(result);
            setError(null);
            return { success: true };
        } catch (err) {
            setError(err.message);
            return { success: false, error: err.message };
        }
    };

    const logout = () => {
        authService.logout();
        setUser(null);
        setError(null);
    };

    const register = async (name, email, password) => {
        try {
            console.log('[AuthContext] Attempting to register user:', { name, email });
            const result = await authService.register(name, email, password);
            console.log('[AuthContext] Registration successful:', result);
            return { success: true };
        } catch (err) {
            console.error('[AuthContext] Registration error:', err);
            return { 
                success: false, 
                error: err 
            };
        }
    };

    const confirmRegistration = async (email, code) => {
        try {
            await authService.confirmRegistration(email, code);
            return { success: true };
        } catch (err) {
            return { success: false, error: err.message };
        }
    };

    const verifyEmail = async () => {
        if (!user?.session) {
            throw new Error('No active session');
        }

        try {
            const message = await authService.verifyEmail(user.session.getIdToken().getJwtToken());
            return { success: true, message };
        } catch (err) {
            return { success: false, error: err.message };
        }
    };

    const value = {
        user,
        loading,
        error,
        login,
        logout,
        register,
        confirmRegistration,
        verifyEmail
    };

    if (error) {
        console.error('Auth Error:', error);
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 