import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    Alert,
    CircularProgress
} from '@mui/material';

const VerifyEmail = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { confirmRegistration } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email;

    if (!email) {
        return (
            <Container maxWidth="sm">
                <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Invalid Access
                    </Typography>
                    <Typography color="error">
                        Please sign up first to verify your email.
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/signup')}
                        >
                            Go to Signup
                        </Button>
                    </Box>
                </Paper>
            </Container>
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const result = await confirmRegistration(email, code);
            if (result.success) {
                navigate('/login', {
                    state: { message: 'Email verified successfully. Please log in.' }
                });
            } else {
                setError(result.error || 'Failed to verify email');
            }
        } catch (err) {
            setError(err.message || 'An error occurred during verification');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Verify Your Email
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Please enter the verification code sent to {email}
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Verification Code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        margin="normal"
                        required
                        disabled={loading}
                    />
                    <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={24} /> : 'Verify Email'}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => navigate('/login')}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
};

export default VerifyEmail; 