import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { getConfig as getSecretsConfig } from './secretsService';

// Get environment variables from runtime config
const getEnvVar = (key) => {
    return window._env_?.[key] || process.env[key];
};

// Environment-specific configuration
const getConfig = async () => {
    const env = process.env.NODE_ENV || 'development';

    try {
        // Get Cognito configuration from Secrets Manager
        const cognitoConfig = await getSecretsConfig();
        
        // API URL configuration - use full URL in production
        const apiUrl = env === 'production' 
            ? (getEnvVar('REACT_APP_API_URL') || 'https://www.tongy.io/api')  // Use full URL with /api in production
            : (getEnvVar('REACT_APP_API_URL') || 'http://localhost:5001');  // Development API URL

        return { baseConfig: cognitoConfig, apiUrl };
    } catch (error) {
        console.error('[AuthService] Error loading configuration');
        throw new Error('Failed to load configuration');
    }
};

// Initialize services
let userPool = null;
let api = null;
let cognitoConfig = null;

const initializeServices = async () => {
    try {
        const config = await getSecretsConfig();
        userPool = new CognitoUserPool({
            UserPoolId: config.cognito.UserPoolId,
            ClientId: config.cognito.ClientId
        });
        console.log('[AuthService] Services initialized successfully');
    } catch (error) {
        console.warn('[AuthService] Failed to initialize services:', error);
    }
};

// Initialize services immediately
initializeServices();

const getCurrentUser = async () => {
    try {
        if (!userPool) {
            await initializeServices();
        }
        
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
            return null;
        }

        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.warn('[AuthService] Error getting session:', err);
                    resolve(null);
                    return;
                }
                if (!session.isValid()) {
                    console.warn('[AuthService] Session is invalid');
                    resolve(null);
                    return;
                }
                resolve(cognitoUser);
            });
        });
    } catch (error) {
        console.warn('[AuthService] Error getting current user:', error);
        return null;
    }
};

const login = async (email, password) => {
    try {
        if (!userPool) {
            await initializeServices();
        }

        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    resolve({ success: true, user: cognitoUser });
                },
                onFailure: (err) => {
                    console.warn('[AuthService] Login failed:', err);
                    resolve({ success: false, error: err.message });
                }
            });
        });
    } catch (error) {
        console.warn('[AuthService] Login error:', error);
        return { success: false, error: error.message };
    }
};

const logout = async () => {
    try {
        if (!userPool) {
            await initializeServices();
        }

        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
        return { success: true };
    } catch (error) {
        console.warn('[AuthService] Logout error:', error);
        return { success: false, error: error.message };
    }
};

const signUp = async (email, password, attributes = []) => {
    try {
        if (!userPool) {
            await initializeServices();
        }

        return new Promise((resolve, reject) => {
            userPool.signUp(email, password, attributes, null, (err, result) => {
                if (err) {
                    console.warn('[AuthService] SignUp error:', err);
                    resolve({ success: false, error: err.message });
                    return;
                }
                resolve({ success: true, user: result.user });
            });
        });
    } catch (error) {
        console.warn('[AuthService] SignUp error:', error);
        return { success: false, error: error.message };
    }
};

const confirmSignUp = async (email, code) => {
    try {
        if (!userPool) {
            await initializeServices();
        }

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    console.warn('[AuthService] Confirm signup error:', err);
                    resolve({ success: false, error: err.message });
                    return;
                }
                resolve({ success: true });
            });
        });
    } catch (error) {
        console.warn('[AuthService] Confirm signup error:', error);
        return { success: false, error: error.message };
    }
};

const authService = {
    getCurrentUser,
    login,
    logout,
    signUp,
    confirmSignUp
};

export default authService; 