import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function Signup() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register } = useAuth();

  // Password validation rules
  const passwordRules = {
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasNumber: /\d/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    isLongEnough: password.length >= 8
  };

  const isPasswordValid = Object.values(passwordRules).every(Boolean);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!isPasswordValid) {
      setError('Password does not meet requirements');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      console.log('[Signup] Attempting to register user');
      const result = await register(name, email, password);
      console.log('[Signup] Registration result:', result);
      
      if (result.success) {
        // Redirect to verification page with email
        navigate('/verify-email', { state: { email } });
      } else {
        // Handle Cognito-specific errors
        const error = result.error;
        console.error('[Signup] Registration error:', error);
        
        if (error.code === 'InvalidPasswordException') {
          setError('Password does not meet requirements: ' + error.message);
        } else if (error.code === 'UsernameExistsException') {
          setError('This email address is already registered.');
        } else if (error.code === 'InvalidParameterException') {
          setError('Invalid input: ' + error.message);
        } else if (error.code === 'CodeDeliveryFailureException') {
          setError('Failed to send verification code. Please try again.');
        } else {
          setError(error.message || 'An error occurred during signup');
        }
      }
    } catch (err) {
      console.error('[Signup] Unexpected error:', err);
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: '100%',
            background: 'linear-gradient(145deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.02) 100%)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          }}
        >
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Sign up
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={password.length > 0 && !isPasswordValid}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={confirmPassword.length > 0 && password !== confirmPassword}
            />

            {/* Password Requirements */}
            <List dense sx={{ mt: 1, mb: 2 }}>
              <ListItem>
                <ListItemIcon>
                  {passwordRules.hasUpperCase ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="At least one uppercase letter" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordRules.hasLowerCase ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="At least one lowercase letter" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordRules.hasNumber ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="At least one number" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordRules.hasSpecialChar ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary={'At least one special character (!@#$%^&*(),.?":{}|<>)'} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordRules.isLongEnough ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="At least 8 characters long" />
              </ListItem>
            </List>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2,
                py: 1.5,
                background: 'linear-gradient(45deg, #90caf9 30%, #ce93d8 90%)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #82bef7 30%, #c77ed0 90%)',
                }
              }}
              disabled={loading || !isPasswordValid}
            >
              {loading ? 'Creating account...' : 'Create Account'}
            </Button>
            <Box sx={{ 
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
              <Typography variant="body2" color="text.secondary">
                Already have an account?
              </Typography>
              <Button
                component={RouterLink}
                to="/login"
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: 'primary.main',
                  color: 'primary.main',
                  '&:hover': {
                    borderColor: 'primary.light',
                    backgroundColor: 'rgba(144, 202, 249, 0.04)',
                  }
                }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Signup; 