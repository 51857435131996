import React, { createContext, useContext, useState, useEffect } from 'react';
import authService from '../services/authService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (currentUser) {
          currentUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.warn('[AuthContext] Error getting user attributes:', err);
              setUser(null);
              return;
            }
            const userData = {};
            attributes.forEach(attr => {
              userData[attr.Name] = attr.Value;
            });
            setUser(userData);
          });
        } else {
          console.log('[AuthContext] No authenticated user');
          setUser(null);
        }
      } catch (error) {
        console.warn('[AuthContext] Error checking auth:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = async (email, password) => {
    try {
      const result = await authService.login(email, password);
      if (result.success) {
        result.user.getUserAttributes((err, attributes) => {
          if (err) {
            console.warn('[AuthContext] Error getting user attributes:', err);
            setUser(null);
            return;
          }
          const userData = {};
          attributes.forEach(attr => {
            userData[attr.Name] = attr.Value;
          });
          setUser(userData);
        });
        setError(null);
      } else {
        setError(result.error);
      }
      return result;
    } catch (error) {
      setError(error.message);
      return { success: false, error: error.message };
    }
  };

  const logout = async () => {
    try {
      const result = await authService.logout();
      if (result.success) {
        setUser(null);
        setError(null);
      } else {
        setError(result.error);
      }
      return result;
    } catch (error) {
      setError(error.message);
      return { success: false, error: error.message };
    }
  };

  const signUp = async (email, password, name) => {
    try {
      const attributes = [
        {
          Name: 'email',
          Value: email
        },
        {
          Name: 'name',
          Value: name
        }
      ];
      const result = await authService.signUp(email, password, attributes);
      if (!result.success) {
        setError(result.error);
      }
      return result;
    } catch (error) {
      setError(error.message);
      return { success: false, error: error.message };
    }
  };

  const confirmSignUp = async (email, code) => {
    try {
      const result = await authService.confirmSignUp(email, code);
      if (!result.success) {
        setError(result.error);
      }
      return result;
    } catch (error) {
      setError(error.message);
      return { success: false, error: error.message };
    }
  };

  const value = {
    user,
    loading,
    error,
    setError,
    login,
    logout,
    signUp,
    confirmSignUp
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 